:root {
  --main-bg-color: #113f67;
  --off-white: #f7f7f7;
  --outline: #889fb3;
}

.navbar {
  text-transform: uppercase;
  background-color: var(--main-bg-color);
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.nav-links {
  flex-direction: column;
  gap: 3.2rem;
  width: 100%;
  padding-left: 3.2rem;
  list-style: none;
  display: flex;
}

.logo {
  background-color: #0000;
  width: 100%;
  margin-top: 2.4rem;
  margin-bottom: 6.2rem;
  padding: 0 1.4rem;
}

.menu-title {
  color: var(--off-white);
  gap: 1.2rem;
  padding-right: 1.2rem;
  font-weight: 100;
  display: flex;
}

.nav-item {
  position: relative;
}

.nav-item h2 {
  font-size: 1.8rem;
}

.menu-dropdown {
  opacity: 0;
  visibility: none;
  height: 0;
  position: absolute;
  top: 0;
  right: -125%;
}

.nav-item:hover .menu-dropdown {
  opacity: 1;
  visibility: visible;
  background-color: #fff;
  width: 125%;
  height: auto;
  transition: all .4s ease-in-out;
}

li {
  list-style: none;
}

.menu {
  text-decoration: none;
}

.eq-link {
  border: .1rem solid #000;
  border-left: none;
  padding-left: .8rem;
  transition: all .1s;
}

.eq-link:hover {
  opacity: .5;
}

.btn--eq {
  color: var(--main-bg-color);
  background-color: #0000;
  border: none;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 600;
}

.side-icon {
  background-color: var(--main-bg-color);
  cursor: pointer;
  border-top-right-radius: .8rem;
  border-bottom-right-radius: .8rem;
  width: 2.4rem;
  height: 20rem;
  display: none;
  position: absolute;
  top: 12.5%;
  left: 0;
}

.chevron-right-icon {
  color: var(--off-white);
  width: 2.4rem;
  height: 2.4rem;
}

.hidden-icon.side-icon {
  display: none;
}

/*# sourceMappingURL=index.3523a1d3.css.map */
