/* SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */

:root {
  --main-bg-color: #113f67;
  --off-white: #f7f7f7;
  --outline: #889fb3;
}

.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  background-color: var(--main-bg-color);
  min-height: 100vh;
}

.nav-links {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding-left: 3.2rem;
}

.logo {
  width: 100%;
  background-color: transparent;
  margin-top: 2.4rem;
  margin-bottom: 6.2rem;
  padding: 0 1.4rem;
}

.menu-title {
  color: var(--off-white);
  font-weight: 100;
  padding-right: 1.2rem;
  display: flex;
  gap: 1.2rem;
}

.nav-item {
  position: relative;
}

.nav-item h2 {
  font-size: 1.8rem;
}

.menu-dropdown {
  position: absolute;
  opacity: 0;
  top: 0;
  height: 0;
  /* transform: translateX(133%); */
  right: -125%;
  visibility: none;
}

.nav-item:hover .menu-dropdown {
  opacity: 1;
  background-color: #fff;
  height: auto;
  width: 125%;
  visibility: visible;
  transition: all 0.4s ease-in-out;
}

li {
  list-style: none;
}

.menu {
  text-decoration: none;
}

.eq-link {
  /* padding: 0.4rem 0.8rem; */
  padding-left: 0.8rem;
  border: solid black 0.1rem;
  border-left: none;
  transition: all 0.1s;
}

.eq-link:hover {
  opacity: 50%;
}

.btn--eq {
  background-color: transparent;
  border: none;
  font-size: 1.8rem;
  color: var(--main-bg-color);
  font-weight: 600;
  font-family: inherit;
}

.side-icon {
  height: 20rem;
  width: 2.4rem;
  background-color: var(--main-bg-color);
  position: absolute;
  top: 12.5%;
  left: 0;
  display: none;
  cursor: pointer;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.chevron-right-icon {
  color: var(--off-white);
  width: 2.4rem;
  height: 2.4rem;
}

.hidden-icon.side-icon {
  display: none;
}
